
export default {
  name: 'PageNotFound',
  head() {
    return this.$utils.head(this.story)
  },
  methods: {
    goHome() {
      this.$nuxt.$router.push('/');
    },
    goMenu() {
      this.$nuxt.$router.push('/order');
    }
  }
}
